.stackFormWrapper {
    width: 100%;
    /* min-width: 400px; */
    gap: 1.5rem;
    padding: 0.5rem;
}

.dialogActions {
    padding: 0 !important;
    justify-content: space-between !important;
}

.dialogTitle {
    text-align: center;
}

.dialogContent {
    overflow-y: visible;
}

.paperProps {
    max-height: 95%;
width: 100%;
}

.dropDownSubjectSearch {
    width: 100% !important;
}