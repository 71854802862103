.listItem {
    display: flex !important;
    font-size: 1rem !important;
    padding: 0.5rem 0 0.5rem 0.5rem !important;
    width: 100% !important;
    align-items: inherit !important;
}

.listItemNoAlign {
    display: flex !important;
    font-size: 1rem !important;
    padding: 0.5rem 0 0.5rem 0.5rem !important;
    width: 100% !important;
}

.listItemIcon {
    min-width: auto !important;
    margin-right: 0.5rem !important;
    padding-top: 0.5rem !important;
}

.listItemIconNoMarginTop {
    min-width: auto !important;
    margin-right: 0.5rem !important;
}

.linearProgressContainer {
    padding: 0 0.5rem;
}

.linearProgress {
    background-color: #f4f2f2 !important;
    height: 0.5rem !important;
    border-radius: 1.25rem !important;
}

.linearProgressText {
    float: right !important;
    padding: 0.5rem !important;
}

.displayInline {
    display: inline !important;
}

.displayBlock {
    display: block !important;
}

.linearProgressBar {
    background-color: #8c1ec8 !important;
    border-radius: 1.25rem;
}

.emailLink {
    text-decoration: none;
}

.buttonsContainer {
    display: flex;
    gap: 1rem;
}

/*  Media Queries */
/* For mobile phones: */
@media (max-width: 600px) {

    .listItem,
    .listItemNoAlign {
        padding: 0.5rem 0 !important;
    }

    .appointmentsHeading {
        padding-top: 1.25rem !important;
        padding-left: 0.5rem !important;
    }

    .appointmentsSubheader {
        font-size: 16px !important;
        padding-left: 0.5rem !important;
    }

    .buttonsContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 1rem !important;
    }
}