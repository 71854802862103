.mainContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100%;
    background-size: cover !important;
}

.errorContainer {
    padding: 0.625rem;
    margin: 4%;
    color: #ba3939;
    background: #ffe0e0;
    border: 1px solid #a33a3a;
    border-radius: 1rem;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
}

.errorMessageWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0.5rem;
}

.errorMessageSpan {
    padding: 0 5px;
}

.emailStyle {
    text-decoration: none;
}

.modalContainer {
    display: flex;
    justify-content: center;
}
.navLink {
    text-decoration: none;
    padding: 0 5px;
}

.infoContainer {   
    background: rgba(255, 255, 255, 0.65);
    backdrop-filter:  blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.4);
    box-shadow:  0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 0.25rem;
    padding: 2rem;  
    color: black;
}


.infoTitle {  
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 0.75rem;
}