.inputList {
    /* this has no visible impact, but is in line with the absolute position spacing.
    doing this ensures that dropping into an empty list will be in the correct visual position */
    padding-left: var(--defaultGrid);
    padding-right: var(--defaultGrid);
    scrollbar-color: dark;
    /* margin-top: 1rem; */
}

.emptyList {
    display: flex;
    padding-left: calc(1rem + 5px);
    padding-top: 1.5rem;
    color: #7f7f7f;
}