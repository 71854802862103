.form {
    margin: 0 auto;
    width: 75vw;
}

.probeEditorButtonWrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
}

.probeEditorFormWrapper {
    margin-top: 1rem;
}

.dropDown__examination {
    position: relative !important;
    z-index: 10 !important;
}