.container1 {
    gap: 1rem !important;
    margin: 0 !important;
    display: grid !important;
    grid-template-rows: auto !important;
}

.formEditorHeader {
    justify-content: space-between;
    margin: 0;
    width: 100%;
}

.exampleList {
    margin: 0;
    padding: 0;
}

.exampleListItem {
    margin: 0 !important;
    padding: 0 !important;
    display: grid !important;
    grid-template-columns: 1fr 1fr !important;
    list-style-position: inside !important;
}

.noPadding {
    padding: 0 !important;
}

.container2 {
    padding: 0.75rem;
    margin: 0;
    width: 100%;
    border-radius: 0.75rem;
}

.container3 {
    justify-content: space-between;
    padding: 0.75rem;
    margin: 0;
    width: 100%;
    border-radius: 0.75rem;
}

.columnContainer {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.inputCheckboxContainer {
    padding-right: 1rem;
}

.backdrop {
    color: #ffffff;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.3) !important;
}

.checkBoxLabel {
    display: flex;
    gap: 0.5rem;
}

.saveButtonContainer {
    display: flex;
    justify-content: flex-end;
    gap: 0.5rem;
}

.searchLoadingWrapper {
    padding: 1.25rem;
}