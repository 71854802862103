/* 10px = 0.625rem
11px = 0.6875rem
12px = 0.75rem
13px = 0.8125rem
14px = 0.875rem
15px = 0.9375rem
16px = 1rem
17px = 1.0625rem
18px = 1.125rem
19px = 1.1875rem
20px = 1.25rem
21px = 1.3125rem
22px = 1.375rem
23px = 1.4375rem
24px = 1.5rem */
:root {
  font-family: Arial;
  height: 100vh;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  --background1: #ffffff;
  --background2: #8c1ec8;
  --background3: #f9f9f9;
  --background4: #008c7d;
  --pdfIconColor: #fa0f00;
  --docIconColor: #0078d4;
  --excelIconColor: #107c41;
  --text1: #ffffff;
  --text2: #000000;
  --activeNavigationLink: #f4f2f2;
  --defaultBorderRadius: 0.5rem;
  --defaultBorderWidth: 2px;
  --navigationBorderRadius: 0.5rem;
  --navigationActivePadding: 0.5rem;
  --defaultGrid: 0.5rem;
  --newWork: #8c1ec8;
  --newWork80: #a34bd3;
  --newWork60: #ba78de;
  --newWork30: #d6bce9;
  --humanFirst:#ff6428;
  --humanFirst80:#ff8353;
  --humanFirst60:#ffa27e;
  --humanFirst30:#f7d1c0;
  --vitalGreen:#008c7d;
  --vitalGreen60:#66bab1;
  --vitalGreen30:#b3ddd8;
  --mentalBlue:#aadcfa;
  --mentalBlue50:#d4eefd;
  --warmGrey:#201e1e;
  --warmGrey80:#4d4b4b;
  --warmGrey60:#bcbcbc;
  --warmGrey30:#f4f2f2;
  --white:#FFFFFF;
  --black:#000000;
  --yellow:#F2F080;
  --error: #dc3545;
  --errorLight:#ff4154;
  --disabledGrey:#E4E4E4;
  --disabledGreyText:#00000042;
  --iconGrey:#6C6C6C;
}
#root {
  font-family: Arial;
  height: 100vh;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  --background1: #ffffff;
  --background2: #8c1ec8;
  --background3: #f9f9f9;
  --background4: #008c7d;
  --pdfIconColor: #fa0f00;
  --docIconColor: #0078d4;
  --excelIconColor: #107c41;
  --text1: #ffffff;
  --text2: #000000;
  --activeNavigationLink: #f4f2f2;
  --defaultBorderRadius: 0.5rem;
  --defaultBorderWidth: 2px;
  --navigationBorderRadius: 0.5rem;
  --navigationActivePadding: 0.5rem;
  --defaultGrid: 0.5rem;
  --newWork: #8c1ec8;
  --newWork80: #a34bd3;
  --newWork60: #ba78de;
  --newWork30: #d6bce9;
  --humanFirst:#ff6428;
  --humanFirst80:#ff8353;
  --humanFirst60:#ffa27e;
  --humanFirst30:#f7d1c0;
  --vitalGreen:#008c7d;
  --vitalGreen60:#66bab1;
  --vitalGreen30:#b3ddd8;
  --mentalBlue:#aadcfa;
  --mentalBlue50:#d4eefd;
  --warmGrey:#201e1e;
  --warmGrey80:#4d4b4b;
  --warmGrey60:#bcbcbc;
  --warmGrey30:#f4f2f2;
  --white:#FFFFFF;
  --black:#000000;
  --yellow:#F2F080;
  --error: #dc3545;
  --errorLight:#ff4154;
  --disabledGrey:#E4E4E4;
  --disabledGreyText:#00000042;
  --iconGrey:#6C6C6C;

}

html,
body {
  -moz-osx-font-smoothing: grayscale;
  background-color: #f9f9f9;
}

.mainContainer {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
}

.outletContent_Wrapper {
  /* padding:  2rem; */
  flex: 1;
  overflow-y: auto;
}

.no-margin {
  margin: 0px;
}

.MuiPaper-root {
  margin-bottom: 0 !important;
  border-radius: 0.25rem !important;
}

.MuiPaperRootCollapse {
  height: 100%;
  border-radius: 0.25rem;
}

.borderRadius {
  border-radius: 0.25rem;
}

.MuiGrid-root {
  z-index: 9 !important;
}

.react-datepicker {
  align-items: center !important;
  font-family: Arial, Helvetica, sans-serif !important;
  border: none !important;
  width: 100% !important;
  font-size: 1rem !important;
}

.react-datepicker__header {
  background-color: #f4f2f2 !important;
}

.react-datepicker__day--selected {
  background-color:#8c1ec8!important;
  border-radius: 1.5rem !important;
  color: #fff !important
}

.react-datepicker__day--keyboard-selected {
  background-color: red!important;
  border-radius: 1.5rem !important;
  color: #fff !important
}

.react-datepicker__day--in-range {
  background-color: #8c1ec8!important;
  border-radius: 1.5rem !important;
  color: #fff!important
}

.react-datepicker__day--in-selecting-range {
  background-color: #ba78de !important;
  border-radius: 1.5rem !important;
  color: #fff !important
}

.react-datepicker__day:hover {
  background-color: #d6bce9 !important;
  border-radius: 1.5rem !important;
}

.react-datepicker__day--today {
  background-color: transparent;
  border: 1px solid #bcbcbc;
  border-radius: 1.5rem !important;
  color: #000000
}

.react-datepicker__month-container {
  float: none !important;
  width: 100% !important
}

.react-datepicker__children-container {
  width: 100% !important;
}

.react-datepicker__week {
  display: flex;
  justify-content: space-evenly;
}

.react-datepicker__day-names {
  display: flex;
  justify-content: space-evenly;
}

.react-calendar {
  width: 100% !important;
  border-radius: 4px;
}

.notistack-SnackbarContainer.zAlert {
  z-index: 10001;
}
/* .react-datepicker__input-container,
.react-datepicker__input-container input {
  height: 100%;
  
} */





