.topActionBar{
    margin-bottom: 1rem;
    display: flex;
    justify-content:space-between;
}

.withDeleteHandlerWrapper {
  text-align: center;
    position: relative;
    background: #fff;
    border: 0.1rem solid var(--warmGrey60);
    border-radius: 0.25rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 200px;
    height: 400px;
  }
  .companyProgressWrapper {
    text-align: center;
    padding: 10px;
    background: #fff;
    border: 0.1rem solid #1a192b;
    border-radius: 0.25rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 200px;
    height: 400px;
  }
  .status_inprogress{
    background: var(--humanFirst80);
  }
  .status_open{
    background: var(--warmGrey30);
  }
  .status_completed{
    background: var(--vitalGreen60);
  }
  .edge_status_inprogress{
    stroke: var(--humanFirst80) !important;
  }
  .edge_status_open{
   stroke: var(--warmGrey60) !important;
  }
  .edge_status_completed{
    stroke: var(--vitalGreen60) !important;
  }
  .is_entrypoint{
    background: var(--mentalBlue);
    cursor: default;
  }
  .actionLabel{
    font-style: italic;
    font-size: 0.75rem;
  }
  
  .withDeleteHandlerWrapper:hover .customNodeDeleteButton {
    visibility: visible;
    opacity: 1;
  }
  
  .customNodeDeleteButton {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.2s ease-in-out;
  }
  .nodeHandle {
    width: 0.6rem!important;
    height: 0.6rem!important;
    border-radius: 50%!important;
    background-color: var(--warmGrey80) !important;
  }
  .nodeHandle_progress {
    width: 0.1rem!important;
    height: 0.rem!important;
    border-radius: 50%!important;
    background-color: var(--warmGrey80) !important;
  }

.connectionDeleteButton {
    width: 3rem;
    height: 3rem;
    border: 5px solid #f7f9fb;
    color: var(--warmGrey60)!important;
    background-color: transparent !important;
    cursor: pointer;

  }
  .buttonLabel {
    position: absolute;
    pointer-events: all;
    transform-origin: center;
  }

  :global(.react-flow__edge) {
    cursor: default !important; 
  }

  .heading_wrapper {
    display: flex;
    align-items: center; 
    justify-content: flex-start; 
    position: relative;
    margin-bottom: 1rem;
  }
  
  .backButton {
    margin-right: 1rem; 
    flex-shrink: 0; 
  }
  
  .heading {
    text-align: center; 
    word-wrap: break-word; 
    white-space: normal; 
    max-width: 80%;
    margin-left: auto;
    margin-right: auto; 
  }

  .noConnection {
    background-color: var(--warmGrey30);
  }

  .actionHeading {
    font-size: 1rem;
  border-radius: 0.25rem;
  padding: 0.5rem 0;
  margin: 0;

  }
  .nodeToolbar {
    /* background-color: var(--white); */
    border-radius: 0.25rem;
    display: flex;
    flex-direction: column;
  }

  .nodeSelected {
  border: 2px solid #8c1ec8 !important; 
  background-color: rgba(140, 30, 200, 0.2) !important; 
  box-shadow: 0px 0px 15px rgba(140, 30, 200, 0.8);
  animation: pulseGlow 1.5s infinite alternate ease-in-out;
  }

  .actionButtonWrapper {
    display: flex;
    gap:1rem
  }
  :global( .react-flow__nodesselection) {
    pointer-events: none !important;
    visibility: hidden;
  }


  

 