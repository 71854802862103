.listItem {
    padding: 0 !important;
    margin-top: 0.75rem !important;
}

.link {
    margin-left: 5px;
    color: #000000;
    text-decoration: none;
}

.accordion {
    box-shadow: none !important;
}

.accordionSummary {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.accordionTitle {
    font-size: 1.25rem;
}

.accordionActions {
    display: flex;
    float: right;
    align-items: center;
}