.btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-family: inherit;
    font-size: 1rem;
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
    padding: 0.5rem 1rem;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  .btn--contained:hover {
    filter: brightness(0.9);
  }
  
  /* OUTLINE HoverColors */
  .btn--outlined:hover {
    background-color: rgba(0, 0, 0, 0.05); 
  }
  
  .btn--primary.btn--outlined:hover {
    background-color:var(--newWork30);
  }
  
  .btn--secondary.btn--outlined:hover {
    background-color:var(--vitalGreen30);
  }
  
  .btn--tertiary.btn--outlined:hover {
    background-color:var(--humanFirst30);
  }

  .btn--iconBase.btn--outlined:hover {
    background-color:var(--disabledGrey);
  }

  .btn--error.btn--outlined:hover {
    background-color:var(--error);
  }
  .btn--white.btn--outlined:hover {
    background-color:var(--white);
  }
  
  /* ButtonVariants */
  .btn--contained {
    color: white;
  }
  
  .btn--outlined{
    background-color: transparent;
    border: 2px solid ;
  }

    /* ButtonColor */
  .btn--primary.btn--contained {
    background-color:var(--newWork) ;

  }

  .btn--primary.btn--outlined {
   color: var(--newWork);
   border-color:var(--newWork) ;
  }

  .btn--secondary.btn--contained {
    background-color:var(--vitalGreen);
  }

  .btn--secondary.btn--outlined {
    color: var(--vitalGreen);
    border-color:var(--vitalGreen) ;
  }

  .btn--tertiary.btn--contained{
    background-color:var(--humanFirst);
  }

  .btn--tertiary.btn--outlined {
    color: var(--humanFirst);
    border-color:var(--humanFirst) ;
  }

  .btn--iconBase.btn--contained {
    background-color:var(--disabledGrey);
  }

  .btn--iconBase.btn--outlined {
   color: var(--disabledGrey);
   border-color:var(--disabledGrey) ;
  }

  .btn--error.btn--contained {
    background-color:var(--error);
  }

  .btn--error.btn--outlined {
   color: var(--error);
   border-color:var(--error) ;
  }
  .btn--white.btn--contained {
    background-color:var(--white);
  }

  .btn--white.btn--outlined {
   color: var(--white);
   border-color:var(--white) ;
  }
  
  /* ButtonSizes */
  .btn--small {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
  }
  
  .btn--medium {
    padding: 0.5rem 1rem;
    font-size: 1rem;
  }
  
  .btn--large {
    padding: 0.75rem 1.5rem;
    font-size: 1.125rem;
  }
  
  /* Icons START & END */
  .btn__icon--start,
  .btn__icon--end {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: color 0.3s ease;
  }
  .btn__icon--start {
    margin-right: 0.5rem;
  }
  
  .btn__icon--end {
    margin-left: 0.5rem;
  }

  /* White color for icons if variant === contained */
  .btn__icon--white {
    color: white ;
  }
  
  /* Color for icons if variant === outlined */
  .btn__icon--primary {
    color: var(--newWork);
  }
  
  .btn__icon--secondary {
    color: var(--vitalGreen);
  }
  
  .btn__icon--tertiary {
    color: var(--humanFirst);
  }

  /* ICONButton */
  .btn--icon {
    background-color: transparent; 
    color: inherit; 
    border: none; 
    padding: 0.5rem; 
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: color 0.3s ease, background-color 0.3s ease;
    border-radius: 50%;

  }
  .btn--icon:hover {
    background-color: rgba(0, 0, 0, 0.05); 
    border-radius: 50%;
  }
  
  .btn--icon.btn--primary {
    color: var(--newWork); 
  }
  
  .btn--icon.btn--secondary {
    color: var(--vitalGreen); 
  }
  
  .btn--icon.btn--tertiary {
    color: var(--humanFirst); 
  }
  .btn--icon.btn--iconBase {
    color: var(--iconGrey); 
  }
  .btn--icon.btn--error {
    color: var(--error); 
  }
  
  /* Icon Button Disabled State */
  .btn--icon.btn--disabled {
    color: rgba(0, 0, 0, 0.26); /* Disabled color */
    cursor: not-allowed;
    pointer-events: none;
  }
  
  /* DisabledButton */
  .btn--disabled {
    background-color: var(--disabledGrey) !important; 
    border-color: #e0e0e0 !important; 
    color:var(--disabledGreyText) !important; 
    cursor: not-allowed;
    filter: none !important; 
    pointer-events: none;
  }
.btn--icon.btn--disabled {
    color:var(--disabledGrey); 
    cursor: not-allowed;
    pointer-events: none;
    background-color: transparent !important; 
    border-radius: 50%; 
  }
  
  .btn--outlined.btn--disabled{
    background-color: transparent !important; 
    border-color: #e0e0e0 !important; 
    color:var(--disabledGreyText) !important; 
    cursor: not-allowed;
    filter: none !important; 
    pointer-events: none;
  }
  .btn--icon.btn--disabled .btn__icon--start,
  .btn--icon.btn--disabled .btn__icon--end {
    color:var(--disabledGrey); 
  }

  .btn__content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    position: relative; 
  }
  
  .btn_disabled_spinnerWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute ;
    width: 100%; 
  }
  .btn_spinner {
    color: var(--newWork) !important
  }
  .btn_disabled_invisibleChildren {
    visibility: hidden; 
  }